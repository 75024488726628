<template>
  <div>
     <AdminNavBar/>
      <h1 class="text-center purple-title">Room Properties</h1>
      <div class="row">
        <div class="offset-md-1 col-md-2">
          <router-link to="/admin-dashboard"><button class="btn purple-btn">Back</button></router-link>
        </div>
         <div class="offset-md-1 col-md-4">
          <p class="text-center">
            <button v-if="!showAddForm" @click="showAddForm = true" class="btn purple-btn mt-4">Add Room Property</button>
            <button class="btn purple-btn mt-4" v-else @click="showAddForm = false">Details</button>
          </p>         
        </div>
      </div>
      <div class="row mt-4" v-if="!showAddForm">
         <div class="col-md-8 offset-md-2">
               <AdminRoomPropertiesTable/>
         </div>
      </div>
        <div class="row mt-4" v-if="showAddForm">
            <div class="col-md-12">
                <div class="card shadow offset-md-3 col-md-7" style="padding-top:10px; padding-left:10px; padding-right:10px; padding-bottom:10px;">
                  <div class="row">
                    <div class="col-md-12">
                    <p class="purple-title">Add new property</p>
                    </div>
                  </div>
                   <div class="row">
                  <div class="col-md-4 mt-4">
                    <label for="property-name-input">Property Name:</label>
                    <input class="form-control" type="text" id="property-name-input" v-model="roomProperty.name"/>  
                  </div>
                  <div class="col-md-4 offset-md-1 mt-4">
                    <label for="property-roomname-input">Room Type:</label>
                    <select class="form-control" id="property-roomname-input" v-model="roomProperty.roomTypeName">
                      <option value="Bathroom">Bathroom</option>
                      <option value="Bedroom">Bedroom</option>
                      <option value="Dining Space">Dining Space</option>
                      <option value="Entryway">Entryway</option>
                      <option value="Kichen">Kitchen</option>
                      <option value="Living/Family Room">Living/Family Room</option>
                      <option value="Hallway">Hallway</option>
                      <option value="Outdoor Space">Outdoor Space</option>
                      <option value="Stairs">Stairs</option>
                      <option value="Laundry Room">Laundry Room</option>
                    </select>
                  </div>
                </div>
                  <div class="row mt-4 mb-4">
              <div class="col-md-2 offset-md-10">
                <button class="btn purple-btn" @click="saveNewRoomProperty()">Save</button>
              </div>
            </div>
           <div class="vld-parent">
                <loading :active.sync="isLoading" 
                :can-cancel="false" 
                
              ></loading>
               </div>
                </div>
            </div>
        </div>
       

  </div>
</template>

<script>
import AdminNavBar from '../../components/Admin/AdminNavBar'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import {adminService} from '../../_services/index'
import AdminRoomPropertiesTable from '../../components/Admin/AdminRoomPropertiesTable'
export default {
  components:{
    AdminNavBar,
    Loading,
    AdminRoomPropertiesTable
  },
  data(){
    return{
      isLoading: false,
      showAddForm: false,
      roomProperty:{
        name: "",
        roomTypeName: ""

      }
    }
  },
  methods:{
    saveNewRoomProperty(){
      this.isLoading = true;
      adminService.addRoomPropertyDefinition(this.roomProperty)
      .then(()=> {
        this.isLoading =false;
        this.$toast.success("Successfully Created Room Property!", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
      }, () => {
        this.isLoading = false;
          this.$toast.error("Unable to create room property.", {
                        position: 'bottom-center',
                        timeout: 5000,
                        hideProgressBar: true
            })
      })
    }
  }
}
</script>

<style>

</style>